// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import MusicPage from '@/components/MusicPage.vue'


const routes = [
  {
	  path: '/musicpage',
	  name: 'musicpage',
	  component: MusicPage,
  },
  {
  	  path: '/',
  	  name: 'home',
  	  component: MusicPage,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
